<template>
  <div class="eduMap">
    <iframe
      src="https://project.zhuneng.cn/d3mdoc/#/iframe?id=5da320f59da64fc6bc86b5ac454abbdf"
      frameborder="0"
    ></iframe>
  </div>
</template>

<script>
export default {
  name: "eduMap",
  data() {
    return {};
  },
  destroyed() {},
  created() {},
  mounted() {},
  computed: {
    communityId() {
      return this.$store.state.communityId;
    },
    userId() {
      return this.$store.state.userId;
    },
    roomId() {
      return this.$store.state.houseId;
    },
    tenantId() {
      return this.$store.state.tenantId;
    },
  },
  methods: {},
};
</script>
<style lang="less" scoped>
.eduMap {
  width: 100%;
  min-height: 100%;
  box-sizing: border-box;
  iframe {
    width: 100%;
    height: 100vh;
  }
}
</style>
